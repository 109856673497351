header {
  display: flex;
  justify-content: center;
  //border-bottom: 1px solid #e4e4e4;
  z-index: 100;
  padding: 20px 0;
  gap: 8px;
  position: relative;

  .main-name-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    gap: 8px;

    p {
      margin: 0;
    }

    .nithin {
      font-size: 24px;
      // padding-left: 10%;
      // margin-bottom: -16px;

    }

    .aishu {
      font-size: 24px;
      //  padding-left: 20%;
    }

    .combaine {

      height: 30px;
      //position: absolute;
      //top: 55%;
      //transform: translateY(-60%);
      //left: 50%;
    }
  }

  .header-border{
    //@media (max-width: 500px) {
    //  display: none;
    //}
  }

  .header-nav-items {
    display: flex;
    align-items: center;

    //@media (max-width: 500px) {
    //  display: none;
    //}
    ul {
      display: flex;
      gap: 24px;
      list-style: none ;
      margin: 0;
      padding-left: 10px;

      a{
        border-radius: 26px;
        background: rgb(237, 237, 228);
        color: inherit;
        text-decoration: none;
        font-size: 18px;
        font-weight: 700;
        padding: 8px 19px;
      }
    }
  }
}