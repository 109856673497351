.EventsWrapper {
  width: 100vw;
  overflow: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;


  .border-image-gallery {
    height: 1px;
    background: #cdcdc0;
    box-shadow: 7px 1px 5px 0.1px #cdcdc0
  }

  .events-container {
    max-width: 1300px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto 10vh;

    .event-title {
      text-align: center;
      margin-top: 24px;
      width: 100%;
      padding: 20px;

      h4 {
        font-size: 40px;
        line-height: 40px;
        margin: 0;
      }

      p {
        max-width: 100%;
        width: 900px;
        margin: 24px auto auto;
      }

      .shape-underline {
        width: 200px;
        margin-top: 16px;
      }
    }

  }

  .events-gallery-container {
    display: flex;
    gap: 24px;
    max-width: 100%;
    justify-content: center;
    padding: 20px;
    margin-top: 24px;
    flex-wrap: wrap;
  }

  .CardWrapper {
    border-radius: 10px;
    min-width: 300px;
    width: 300px;
    max-width: 100%;
    background-color: #eeeee1;
    padding: 24px;
    position: relative;
    min-height: 300px;
    flex: 1;

    .card-month {
      text-transform: uppercase;
      font-size: 12px;
      line-height: 0;
      margin: 0;
    }

    .card-date {
      font-size: 36px;
      line-height: 50px;
      font-weight: 600;
      margin: 0;
      margin-top: 5px;
      margin-left: -4px;
    }

    p {
      margin: 0;
    }

    .card-contain {
      margin-top: 16px;

      .card-title {
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;
        margin: 0px 0 0;
      }
    }


    .card-action {
      position: absolute;
      bottom: 24px;
      left: 24px;
      right: 24px;
      margin-top: 24px;
      display: flex;
      justify-content: space-between;

      p {
        font-size: 12px;
      }
    }
  }
}