:root {
  --primary: rgb(85, 38, 255);
  --secondary: orange;
  --font-color-1: #000;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}


.allura-regular {
  font-family: "Allura", cursive;
  font-weight: 400;
  font-style: normal;
}


.acme-regular {
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  // font-weight: <weight>;
  font-style: normal;
}

.berkshire-swash-regular {
  font-family: "Berkshire Swash", serif;
  font-weight: 400;
  font-style: normal;
}

.nunito {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  //overflow: hidden;
  font-family: "Marck Script", cursive;
  background: rgb(252, 250, 246);
  color: #202641;
  //background: #1a1000;
  //color: #FFD700; /* Golden color */
  //text-shadow: 0 0 10px #FFD700,
  //0 0 20px #FF8C00,
  //0 0 30px #FF4500;
  //0 0 40px #FF8C00, /* Slight orange for warm glow */
  //0 0 70px #FF8C00,
  //0 0 100px #FF4500; /* Darker glow */
}


code {
  background-color: rgb(243, 243, 243);
  padding: 4px 8px;
  border-radius: 4px;
}