.home-wrapper {
  position: relative;
  max-height: 100vh;
  height: 100vh;
  max-width: 1600px;
  margin: 0 auto;
  box-shadow: 0 0 5px 3px #e1e3cf;

  .HomeComponent-Wrapper {
    background: rgb(252, 250, 246);
    display: flex;
    justify-content: space-between;
    padding-right: 100px;
    padding-left: 32px;
    align-items: center;
    margin: auto;
    color: #202641;
    flex-direction: row;
    overflow: auto;
    height: calc(100vh - 100px);

    @media (max-width: 900px) {
      flex-direction: column;
      padding-right: 16px;
      padding-left: 16px;
    }

    .first-flex-component {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1 1 0;


      .dummy-height-adjuster-div {
        height: 10vh;
      }

      .highlight-image {
        //border: 1px solid #202641;
        width: 600px;
        height: 600px;
        border-radius: 50%;
        background: #edede4;
        position: relative;

        .border-image {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          bottom: 0;
          left: 0;
        }
      }

      @media (max-width: 1400px) {
        //flex: 0.8;
        //margin-top: 34px;
        //margin-bottom: 34px;

        .highlight-image {
          width: 400px;
          height: 400px;
        }
      }

      @media (max-width: 900px) {
        flex: 0.8;
        margin-top: 34px;
        margin-bottom: 34px;

        .highlight-image {
          width: 300px;
          height: 300px;
        }

        .dummy-height-adjuster-div {
          height: 0;
        }
      }

      @media (max-width: 500px){
        flex: 0.8;
        .highlight-image {
          width: 250px;
          height: 250px;
        }
      }
    }


    .shape-underline-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      z-index: 1;

      .shape-underline {
        width: 40%;
        margin-top: 24px;
        height: auto;
      }

      @media (max-width: 900px) {
        justify-content: center;

        .shape-underline {
          width: 300px;
          max-width: 70%;
          margin-top: 16px;
          height: auto;
        }
      }

      @media (max-width: 500px) {
        .shape-underline {
          max-width: 70%;
          margin-top: 16px;
          height: auto;
        }
      }
    }



    .main-name-wrapper {
      position: relative;
      z-index: 1;

      .nithin {
        margin-bottom: 4px;

      }

      .aishu {
        margin-top: -4px;
        display: block;
        padding-top: 0px;
      }

      .combaine {
        height: 50px;
      }
    }

    .count-down-timer {
      font-size: 60px;
    }

    .shape-left-feather {
      height: 60px;
      margin-left: 24px;

      @media (max-width: 900px) {
        height: 30px;
      }

    }

    .shape-right-feather {
      height: 60px;
      margin-right: 24px;

      @media (max-width: 900px) {
        height: 30px;
      }
    }

    h1 {
      font-size: 48px;
      margin: 0;
    }

    p {
      margin: 8px 0 0;
    }

    .main-names {
      margin: 8px 0 0;
      font-size: 100px;

      @media (max-width: 1400px) {
        font-size: 70px;
      }

      @media (max-width: 900px) {
        font-size: 3.2rem;
      }

      @media (max-width: 600px) {
        font-size: 2.8rem;
      }

      @media (max-width: 400px) {
        font-size: 2.5rem;
      }
    }

    .invite-text {
      font-size: 20px;
      margin-top: 16px;
      text-transform: uppercase;
      letter-spacing: 3px;
      z-index: 1;
      line-height: 32px;
      text-align: right;

      @media (max-width: 1400px) {
        font-size: 1rem;
        letter-spacing: 2px;
      }

      @media (max-width: 900px) {
        font-size: 1rem;
        text-align: center;
        letter-spacing: 2px;
      }
    }

    .invite-text-2 {
      margin-top: 16px;

      @media (max-width: 900px) {
        margin-top: 8px;
      }
    }

    .second-flex-component {
      //margin-top: 0;
      margin-bottom: 100px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      flex: 1 1 0;

      @media (max-width: 900px) {
        //margin-bottom: 0;
        align-items: center;
        margin-bottom: 34px;
        // margin-top: 8px;
      }

      .save-the-date {
        //background: red;
        margin-top: 16px;

        .save-the-date-day {
          font-size: 24px;
          align-self: flex-end;
          line-height: 20px;

          @media (max-width: 900px) {
            font-size: 20px;
          }
        }

        .save-the-date-date {
          color: #afae98;
          font-size: 90px;
          padding-left: 16px;
          margin-bottom: 16px;
          line-height: 20px;

          @media (max-width: 1400px) {
            font-size: 70px;
            margin-bottom: 8px;
          }

          @media (max-width: 900px) {
            font-size: 50px;
            margin-bottom: 8px;
          }
        }

        .save-the-date-month {
          color: #afae98;
          font-size: 36px;
          padding-left: 8px;
          align-self: flex-end;
          line-height: 20px;

          @media (max-width: 1400px) {
            font-size: 30px;
          }

          @media (max-width: 900px) {
            font-size: 24px;

          }
        }
      }

    }

    .content {
      margin-top: 34px;
      font-weight: 500;
    }


  }

  .share-components-wrapper {
    //position: relative;

    .shape-top-left {
      position: absolute;
      left: 0;
      top: 0;
      width: 150px;

      @media (max-width: 900px) {
        width: 60px;
      }
    }

    .shape-top-right {
      position: absolute;
      right: 0;
      top: 0;
      width: 150px;
      z-index: 100;

      @media (max-width: 900px) {
        width: 60px;
      }
    }

    .shape-top-right-bubble {
      position: absolute;
      right: 8vw;
      top: 0;
      width: 300px;

      @media (max-width: 900px) {
        width: 150px;
      }
    }

    .shape-top-right-leaf {
      position: absolute;
      right: 10vw;
      top: 0;
      width: 200px;

      @media (max-width: 900px) {
        right: 20vw;
        width: 70px;
      }
    }

    .shape-left-scattered-bubble {
      position: absolute;
      left: 0;
      top: 15vh;
      width: 150px;

      @media (max-width: 900px) {
        width: 80px;
      }
    }

    .shape-left-bottom-half-border {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 40vw; /* Full width of the container */
      height: auto;

      @media (max-width: 900px) {
        width: 50vw;
      }
    }

    .shape-left-bottom-half-border img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%; /* Adjust the width based on your needs */
      height: auto; /* Maintain aspect ratio */
    }

    .shape-left-scattered-bottom-bubbles {
      position: absolute;
      left: 40px;
      bottom: 20px;
      height: 200px;

      @media (max-width: 900px) {
        height: 100px;
        bottom: 20vh;
        right: 20vw;
        left: auto;
      }

    }

    .shape-bottom-leaf {
      position: absolute;
      left: 20vw;
      bottom: 0;
      height: 180px;
      z-index: 1;

      @media (max-width: 1400px) {
        height: 100px;
      }

      @media (max-width: 900px) {
        height: 70px;
      }
    }

    .shape-bottom {
      position: absolute;
      left: 40vw;
      bottom: 0;
      height: 180px;

      @media (max-width: 1400px) {
        left: 50vw;
        height: 100px;
      }

      @media (max-width: 900px) {
        left: 50vw;
        height: 70px;
      }
    }

    .shape-middle-scattered-bubble {
      position: absolute;
      top: 55vh;
      right: 35vw;
      height: 180px;

      @media (max-width: 900px) {
        height: 120px;
        top: 40vh;
      }
    }
  }
}